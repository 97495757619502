<template>
    <div class="view pa24">
        <div class="mb20">
            <el-tabs v-model="webType">
                <el-tab-pane label="月租车" name="monthlyRentalCar"></el-tab-pane>
                <el-tab-pane label="临时车" name="temporaryVehicle"></el-tab-pane>
                <el-tab-pane label="一位多车" name="onePersonWithManyCars"></el-tab-pane>
                <el-tab-pane label="黑名单" name="blacklist"></el-tab-pane>
                <el-tab-pane label="待审核" name="toBeReviewed"></el-tab-pane>
            </el-tabs>
        </div>
        <div v-if="webType === 'monthlyRentalCar'">
            <monthlyRentalCarTable />
        </div>
        <div v-if="webType === 'temporaryVehicle'">
            <temporaryVehicleTable />
        </div>
        <div v-if="webType === 'onePersonWithManyCars'">
            <onePersonWithManyCarsTable />
        </div>
        <div v-if="webType === 'blacklist'">
            <blacklistTable />
        </div>
        <div v-if="webType === 'toBeReviewed'">
            <toBeReviewedTable />
        </div>
    </div>
</template>

<script>
import monthlyRentalCarTable from "./pages/monthlyRentalCarTable";
import temporaryVehicleTable from "./pages/temporaryVehicleTable";
import onePersonWithManyCarsTable from "./pages/onePersonWithManyCarsTable";
import blacklistTable from "./pages/blacklistTable";
import toBeReviewedTable from "./pages/toBeReviewedTable";
export default {
    name: "vehicleManagement",
    components: {
        monthlyRentalCarTable,
        temporaryVehicleTable,
        onePersonWithManyCarsTable,
        blacklistTable,
        toBeReviewedTable
    },
    data() {
        return {
            webType: "monthlyRentalCar",
        };
    },
    filters: {

    },
    created() {

    },
    methods: {

    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>